import React, { useContext, useEffect, useState } from "react";
import CommonLoader from "../../common/CommonLoader";
import { useNavigate } from "react-router-dom";
import { RestaurantContext } from "../../data/contexts/RestaurantContext";
import APIController from "../../data/APIController";
import { GET_RESTAURANT_MENU } from "../../constants/ApiEndpoints";
import CommonMenuCategory from "../../common/CommonMenuCategory";

const Menu = () => {
  const { restaurant } = useContext(RestaurantContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [menu, setMenu] = useState([]);

  const getRestaurantMenu = () => {
    setLoading(true);
    APIController.get(GET_RESTAURANT_MENU + restaurant?.id ?? "")
      .then((response) => {
        console.log("Restaurant Menu: ", response.data.data);
        setMenu(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("API Error: ", error);
      });
  };

  useEffect(() => {
    getRestaurantMenu();
  }, []);

  return (
    <>
      {loading ? (
        <div
          className="pt-12"
          style={{
            height: "80vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CommonLoader width={128} height={128} />
          <p className="font-medium mt-2">Updating Menu</p>
        </div>
      ) : (
        <>
          <div className="mt-24 pb-24 px-6">
            {menu.length > 0 ? (
              menu?.map((item) => (
                <CommonMenuCategory
                  key={item?.id}
                  item={item}
                  onNavigate={(product) =>
                    navigate(`/menu/item/${product?.id ?? "'"}`)
                  }
                  onChangeStatus={(product) => {
                    if (product?.available) {
                      console.log("Product is available - Make it unavailable");
                    } else {
                      console.log(
                        "Product is not available - Make it available"
                      );
                    }
                  }}
                />
              ))
            ) : (
              <div
                style={{
                  height: "70vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="font-semibold mt-4 text-lg">
                  You have not uploaded any menu items for your store
                </p>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Menu;
