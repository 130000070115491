import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { FaChevronLeft } from "react-icons/fa6";
import { AiFillPrinter } from "react-icons/ai";
import { FaMapMarkerAlt } from "react-icons/fa";

import CommonLoader from "../../common/CommonLoader";
import {
  ACCEPT_ORDER,
  DELAY_ORDER,
  GET_ORDER_DETAILS,
  ORDER_PICKED_UP,
  ORDER_READY,
  REFUND_FULL_ORDER,
  REFUND_PARTIAL_ORDER,
  REJECT_ORDER,
} from "../../constants/ApiEndpoints";
import APIController from "../../data/APIController";
import { showAPIError } from "../../helpers/FieldHelpers";
import { showToastMessage } from "../../helpers/ToastHelpers";
import PlaySound from "../../sound/PlaySound";

import { toSentenceCase } from "../../helpers/StringHelpers";
import AwaitingButtonGroup from "../../common/buttons/AwaitingButtonGroup";
import PreparingButtonGroup from "../../common/buttons/PreparingButtonGroup";
import { formatDateTime, formatOrderDate } from "../../helpers/DateHelpers";
import OrderActionModal from "../../common/modals/OrderActionModal";
import ReadyButtonGroup from "../../common/buttons/ReadyButtonGroup";
import DelayOrderModal from "../../common/modals/DelayOrderModal";
import RefundAmountModal from "../../common/modals/RefundAmountModal";
import CancelOrderModal from "../../common/modals/CancelOrderModal";
import { closePopup, openPopup } from "../../helpers/ModalHelpers";
import CommonOrderProduct from "../../common/CommonOrderProduct";
import { getFormattedOrderStatus } from "../../helpers/OrderHelpers";

const OrderDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [orderDetails, setOrderDetails] = useState(null);

  // Order Progress States
  const [acceptingOrder, setAcceptingOrder] = useState(false);
  const [decliningOrder, setDecliningOrder] = useState(false);
  const [readyingOrder, setReadyingOrder] = useState(false);
  const [delayingOrder, setDelayingOrder] = useState(false);
  const [refundingOrder, setRefundingOrder] = useState(false);

  // Order Actions
  const [showActions, setShowActions] = useState(false);
  const [showDelay, setShowDelay] = useState(false);
  const [showRefund, setShowRefund] = useState(false);
  const [showCancel, setShowCancel] = useState(false);

  const [shouldPlay, setShouldPlay] = useState(false);

  // State Loading
  const [printing, setPrinting] = useState(false);

  let timer = null;

  // Function to run after 5 seconds of inactivity
  const handleInactivity = () => {
    console.log("User has been inactive for 10 seconds");
    // navigate("/restaurant");
    // You can call any function here
  };

  // Reset the inactivity timer
  const resetTimer = () => {
    clearTimeout(timer);
    timer = setTimeout(handleInactivity, 10000); // Reset timer to 5 seconds after any activity
  };

  useEffect(() => {
    // Setup event listeners to manage user activity
    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keypress", resetTimer);
    window.addEventListener("scroll", resetTimer);
    window.addEventListener("touchmove", resetTimer);

    // Set the initial timer
    timer = setTimeout(handleInactivity, 10000);

    // Cleanup on component unmount
    return () => {
      clearTimeout(timer); // Clear the timeout to prevent the function from being called after component unmount
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keypress", resetTimer);
      window.removeEventListener("scroll", resetTimer);
      window.removeEventListener("touchmove", resetTimer);
    };
  }, []);

  useEffect(() => {
    getOrderDetails();
  }, []);

  useEffect(() => {
    console.log("ORDER DETAILS: ", orderDetails);
    if (orderDetails) {
      if (orderDetails.status === "awaiting") {
        setShouldPlay(true);
      }
    }
  }, [orderDetails]);

  const getOrderDetails = () => {
    console.log("Order ID: ", id);
    setLoading(true);
    APIController.get(`${GET_ORDER_DETAILS}${id}`)
      .then((response) => {
        console.log("Order Details Response: ", response);

        if (response.data.success == false) {
          navigate(-1);
          showAPIError(response.data?.message ?? "");
          return;
        }

        setOrderDetails(response.data?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error Fetching Order Details: ", error);
      });
  };

  const acceptOrder = () => {
    setAcceptingOrder(true);
    APIController.patch(ACCEPT_ORDER, {
      orderId: orderDetails?.id ?? "",
    })
      .then((response) => {
        console.log("ACCEPT ORDER RESPONSE: ", response);
        setShouldPlay(false);
        getOrderDetails();
        setAcceptingOrder(false);
        setTimeout(() => {
          printOrder();
        }, 500);
      })
      .catch((error) => {
        console.log("Error Accepting Order: ", error);
        setAcceptingOrder(false);
      });
  };

  const rejectOrder = () => {
    setDecliningOrder(true);
    APIController.patch(REJECT_ORDER, {
      orderId: orderDetails?.id ?? "",
    })
      .then((response) => {
        console.log("REJECT ORDER RESPONSE: ", response);
        setShouldPlay(false);
        window.history.back();
      })
      .catch((error) => {
        console.log("Error Accepting Order: ", error);
        setDecliningOrder(false);
      });
  };

  const markOrderReady = () => {
    setReadyingOrder(true);
    APIController.patch(ORDER_READY, {
      orderId: orderDetails?.id ?? "",
    })
      .then((response) => {
        console.log("ORDER READY RESPONSE: ", response);
        setReadyingOrder(false);
        window.history.back();
      })
      .catch((error) => {
        console.log("Error Accepting Order: ", error);
        setReadyingOrder(false);
      });
  };

  const markOrderPickedUp = () => {
    APIController.patch(ORDER_PICKED_UP, {
      orderId: orderDetails?.id ?? "",
    })
      .then((response) => {
        console.log("ORDER PICKED UP RESPONSE: ", response);
        window.history.back();
      })
      .catch((error) => {
        console.log("Error Accepting Order: ", error);
      });
  };

  const refundPartialOrder = (amount) => {
    if (!amount || amount == 0.0) {
      return alert("Please enter an amount greater than $0.00");
    }

    setRefundingOrder(true);
    APIController.patch(REFUND_PARTIAL_ORDER, {
      orderId: orderDetails?.id ?? "",
      amount: amount,
    })
      .then((response) => {
        console.log("REFUND FULL ORDER RESPONSE: ", response);
        getOrderDetails();

        setRefundingOrder(false);
        setShowRefund(false);

        if (response.data.success == false) {
          return openPopup({
            title: "Error Refunding Amount",
            description: response?.data.message ?? "",
            positiveButtonTap: () => closePopup(),
            positiveButtonText: "Close",
          });
        }
      })
      .catch((error) => {
        console.log("Error Refunding Full Order: ", error);
      });
  };

  const refundFullOrder = () => {
    setShowCancel(false);
    APIController.patch(REFUND_FULL_ORDER, {
      orderId: orderDetails?.id ?? "",
    })
      .then((response) => {
        console.log("REFUND FULL ORDER RESPONSE: ", response);
        if (response.data.success == false) {
          showAPIError(response?.data?.message ?? "");
          return;
        }
        getOrderDetails();
      })
      .catch((error) => {
        console.log("Error Refunding Full Order: ", error);
      });
  };

  const delayOrder = (minutes) => {
    setDelayingOrder(true);
    APIController.patch(DELAY_ORDER, {
      orderId: orderDetails?.id ?? "",
      minutes: minutes,
    })
      .then((response) => {
        console.log("ORDER DELAYED RESPONSE: ", response);
        setShowDelay(false);
        setDelayingOrder(false);
        getOrderDetails();
      })
      .catch((error) => {
        console.log("Error Delaying Order: ", error);
        setDelayingOrder(false);
      });
  };

  const printOrder = () => {
    setPrinting(true);
    showToastMessage("Printing Order", "Your order will print momentarily");
    const message = JSON.stringify({
      type: "printOrder",
      orderId: id,
    });
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(message);
    }

    setTimeout(() => {
      setPrinting(false);
    }, 3000);
  };

  return (
    <>
      <PlaySound shouldPlay={shouldPlay} />
      <OrderActionModal
        isVisible={showActions}
        onClose={() => setShowActions(false)}
        onDelay={() => {
          setShowActions(false);
          setShowDelay(true);
        }}
        onRefund={() => {
          setShowActions(false);
          setShowRefund(true);
        }}
        onCancel={() => {
          setShowActions(false);
          setShowCancel(true);
        }}
      />

      <DelayOrderModal
        isVisible={showDelay}
        isDelaying={delayingOrder}
        onClose={() => setShowDelay(false)}
        onSubmit={delayOrder}
      />

      <RefundAmountModal
        isVisible={showRefund}
        onClose={() => setShowRefund(false)}
        refunding={refundingOrder}
        onRefund={(value) => {
          console.log("REFUND VALUE: ", value);
          refundPartialOrder(value);
        }}
      />

      <CancelOrderModal
        isVisible={showCancel}
        onConfirm={() => refundFullOrder()}
        onClose={() => setShowCancel(false)}
      />

      {loading ? (
        <>
          <div
            className="pt-12"
            style={{
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CommonLoader width={128} height={128} />
            <p className="font-medium mt-2">Updating Order Details</p>
          </div>
        </>
      ) : (
        <>
          {/* Order Details Header */}
          <div className="flex flex-row justify-center items-center fixed top-0 pt-6 pb-4 px-6 bg-white w-screen">
            <FaChevronLeft
              size={20}
              onClick={() => {
                navigate("/restaurant");
              }}
            />

            <div style={{ flex: 1 }} />

            {orderDetails?.delivery?.deliveryURL ? (
              <div
                onClick={() =>
                  (window.location.href = orderDetails?.delivery?.deliveryURL)
                }
                className="h-10 w-10 rounded-full flex justify-center items-center mr-2"
                style={{ backgroundColor: "#4166F5" }}
              >
                <FaMapMarkerAlt size={24} color={"#ffffff"} />
              </div>
            ) : null}

            <div
              onClick={printOrder}
              className="cursor-pointer h-10 w-10 rounded-full flex justify-center items-center"
              style={{ backgroundColor: "#FA9235" }}
            >
              <AiFillPrinter size={28} color={"#ffffff"} />
            </div>
          </div>

          {/* Order Details Header */}
          <div className="px-6 mt-20 pb-4">
            <h4 className="text-black font-bold text-xl">
              Order #{orderDetails?.bookingId?.toUpperCase() ?? ""}
            </h4>

            <p className="text-black font-bold text-lg">
              {getFormattedOrderStatus(orderDetails?.status ?? "awaiting")}
            </p>

            <h5 className="text-black font-medium text-lg mt-1">
              {orderDetails?.customer?.firstName ?? ""}{" "}
              {orderDetails?.customer?.lastName ?? ""}
            </h5>

            <p className="text-black font-medium text-lg">
              {toSentenceCase(orderDetails?.orderType ?? "pickup")}
            </p>
          </div>

          {/* Order Time */}
          <div className="flex flex-col justify-center items-center px-6 border-t-1 border-b-1 border-slate-300">
            <h4 className="text-black font-bold text-4xl pt-3 pb-2">
              {orderDetails?.orderTime
                ? formatOrderDate(
                    orderDetails?.orderTime ?? new Date().toISOString()
                  )
                : "ASAP"}
            </h4>

            <p className="text-black font-medium text-lg pb-3">
              Requested for:{" "}
              {orderDetails?.requestedTime
                ? formatOrderDate(
                    orderDetails?.requestedTime ?? new Date().toISOString()
                  )
                : "ASAP"}
            </p>
          </div>

          {/* Order Actions */}
          <div className="mt-4 mb-6 px-6">
            {/* Awaiting Actions */}
            {orderDetails.status == "awaiting" ? (
              <AwaitingButtonGroup
                orderId={orderDetails?.id ?? ""}
                onAccept={acceptOrder}
                onReject={rejectOrder}
                acceptingOrder={acceptingOrder}
                rejectingOrder={decliningOrder}
              />
            ) : orderDetails.status == "preparing" ? (
              <PreparingButtonGroup
                onReady={markOrderReady}
                onActions={() => setShowActions(true)}
                isReadying={readyingOrder}
              />
            ) : orderDetails.status == "ready" ? (
              <ReadyButtonGroup
                orderType={orderDetails?.orderType ?? "pickup"}
                onPickedUp={markOrderPickedUp}
                onPrint={printOrder}
                onActions={() => setShowActions(true)}
              />
            ) : null}
          </div>

          {/* Order Items */}
          <div>
            {orderDetails?.items && orderDetails?.items?.length > 0
              ? orderDetails?.items?.map((item) => (
                  <CommonOrderProduct key={item?.id ?? ""} item={item} />
                ))
              : null}
          </div>

          {/* Order Notes */}
          {orderDetails?.orderNotes ? (
            <div className="px-6 pb-4 mt-4 border-b-1 border-slate-300">
              <h4 className="text-black text-lg font-bold">Order Notes</h4>
              <p className="text-black text-base font-normal mt-1">
                {orderDetails?.orderNotes ?? ""}
              </p>
            </div>
          ) : null}

          {/* Customer Details */}
          <div className="px-6">
            <div className="flex flex-row justify-start pt-2 pb-4 items-center border-slate-300 border-b-1">
              <img
                className="w-20 h-20 object-cover rounded-full"
                src={
                  orderDetails?.customer?.profileImage ??
                  "https://cdn.appetitepos.com.au/user_icons/ic_blank_user.webp"
                }
              />

              <div className="flex flex-col ml-4">
                <h4 className="font-medium text-black text-lg">
                  {orderDetails?.customer?.firstName ?? ""}{" "}
                  {orderDetails?.customer?.lastName ?? ""}
                </h4>

                <p className="font-normal text-black text-base">
                  {orderDetails?.customer?.mobile ?? ""}
                </p>

                <p className="font-normal text-black text-base">
                  Previous Orders: {orderDetails?.previousOrders ?? 0}
                </p>
              </div>
            </div>
          </div>

          {/* Totals */}
          <div className="px-6 mt-4">
            <div className="flex flex-row justify-center items-center">
              <h4 className="font-medium text-lg text-black">Subtotal</h4>
              <div style={{ flex: 1 }} />
              <p className="font-medium text-lg text-black">
                ${Number(orderDetails?.subtotal ?? 0.0).toFixed(2)}
              </p>
            </div>

            {orderDetails?.transactionFee > 0 ? (
              <div className="flex flex-row justify-center items-center mt-2">
                <h4 className="font-medium text-lg text-black">Surcharge(s)</h4>
                <div style={{ flex: 1 }} />
                <p className="font-medium text-lg text-black">
                  ${Number(orderDetails?.transactionFee ?? 0.0).toFixed(2)}
                </p>
              </div>
            ) : null}

            {orderDetails?.deliveryFee > 0 ? (
              <div className="flex flex-row justify-center items-center mt-2">
                <h4 className="font-medium text-lg text-black">Delivery Fee</h4>
                <div style={{ flex: 1 }} />
                <p className="font-medium text-lg text-black">
                  ${Number(orderDetails?.deliveryFee ?? 0.0).toFixed(2)}
                </p>
              </div>
            ) : null}

            {orderDetails?.refundedAmount > 0 ? (
              <div className="flex flex-row justify-center items-center mt-2">
                <h4 className="font-medium text-lg text-black">
                  Refunded Amount
                </h4>
                <div style={{ flex: 1 }} />
                <p className="font-medium text-lg text-black">
                  ${Number(orderDetails?.refundedAmount ?? 0.0).toFixed(2)}
                </p>
              </div>
            ) : null}

            {/* Discount */}
            {orderDetails?.discountCode ? (
              <div className="flex flex-row justify-center items-center mt-2">
                <h4 className="font-medium text-lg text-[#FF4A49]">
                  Discount ({orderDetails?.discountCode ?? ""})
                </h4>
                <div style={{ flex: 1 }} />
                <p className="font-medium text-lg text-[#FF4A49]">
                  ${Number(orderDetails?.discount ?? 0.0).toFixed(2)}
                </p>
              </div>
            ) : null}

            {/* Service Fee */}
            {orderDetails?.serviceFee > 0 ? (
              <div className="flex flex-row justify-center items-center mt-2">
                <h4 className="font-medium text-lg text-black">Service Fee</h4>
                <div style={{ flex: 1 }} />
                <p className="font-medium text-lg text-black">
                  ${Number(orderDetails?.serviceFee ?? 0.0).toFixed(2)}
                </p>
              </div>
            ) : null}

            <div className="flex flex-row justify-center items-center mt-2">
              <h4 className="font-medium text-lg text-black">Total</h4>
              <div style={{ flex: 1 }} />
              <p className="font-medium text-lg text-black">
                ${Number(orderDetails?.total ?? 0.0).toFixed(2)}
              </p>
            </div>
          </div>

          <div className="px-6 pt-4 mt-4 pb-8 border-t-1 border-slate-300">
            <h4 className="text-black text-lg font-bold">Order Metadata</h4>

            <div className="flex flex-row justify-center items-center mt-3">
              <h4 className="font-medium text-base text-black">
                Payment Status
              </h4>
              <div style={{ flex: 1 }} />
              <p className="font-bold text-base text-black">
                {orderDetails?.payment?.status}
              </p>
            </div>

            <div className="flex flex-row justify-center items-center mt-2">
              <h4 className="font-medium text-base text-black">
                Payment Approved
              </h4>
              <div style={{ flex: 1 }} />
              <p className="font-bold text-base text-black">
                {formatDateTime(
                  orderDetails?.payment?.approvedAt ?? new Date()
                )}
              </p>
            </div>

            {orderDetails?.orderType == "delivery" ? (
              <div className="flex flex-row justify-center items-center mt-2">
                <h4 className="font-medium text-base text-black">
                  Delivery Partner
                </h4>
                <div style={{ flex: 1 }} />
                <p className="font-bold text-base text-black">
                  {toSentenceCase(orderDetails?.fulfillmentType ?? "pickup")}
                </p>
              </div>
            ) : null}

            {orderDetails?.delivery?.bookedAt ? (
              <div className="flex flex-row justify-center items-center mt-2">
                <h4 className="font-medium text-base text-black">
                  Delivery Booked
                </h4>
                <div style={{ flex: 1 }} />
                <p className="font-bold text-base text-black">
                  {formatDateTime(
                    orderDetails?.delivery?.bookedAt ?? new Date()
                  )}
                </p>
              </div>
            ) : null}
          </div>
        </>
      )}
    </>
  );
};

export default OrderDetails;
