import React, { useContext } from "react";
import { Switch } from "@nextui-org/react";
import { RestaurantContext } from "../data/contexts/RestaurantContext";
import { UPDATE_RESTAURANT_STATUS } from "../constants/ApiEndpoints";
import APIController from "../data/APIController";
import { showAPIError } from "../helpers/FieldHelpers";
import EventBus from "../helpers/EventBus";
import CommonOptionSelector from "./CommonTypeSelector";

const OrderStateHeader = ({
  title,
  orderTypes,
  currentIndex,
  setCurrentIndex,
}) => {
  const { restaurant } = useContext(RestaurantContext);

  const updateRestaurantStatus = () => {
    APIController.patch(UPDATE_RESTAURANT_STATUS)
      .then((response) => {
        console.log("Update Restaurant Profile Response: ", response);

        if (response.data.success == false) {
          showAPIError(response.data?.message ?? "");
          return;
        }

        EventBus.emit("updateRestaurant");
      })
      .catch((error) => {
        console.log("Error Updating Restaurant Status: ", error);
      });
  };

  return (
    <>
      <div className="flex flex-col fixed top-0 left-0 right-0 bg-white px-6 pt-6 pb-1">
        <div className="flex flex-row ">
          <div className="flex flex-col">
            <h1 className="font-bold text-lg">{restaurant?.name ?? ""}</h1>
            <h4 className="font-medium text-lg">{title}</h4>
          </div>

          <div style={{ flex: 1 }} />
          <Switch
            onChange={(value) => {
              updateRestaurantStatus();
            }}
            isSelected={restaurant?.online ?? false}
            color={restaurant?.online ? "success" : "danger"}
          />
        </div>
        <CommonOptionSelector
          options={orderTypes}
          value={currentIndex}
          onChange={setCurrentIndex}
        />
      </div>
    </>
  );
};

export default OrderStateHeader;
