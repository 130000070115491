const CommonOrderProduct = ({ item }) => {
  return (
    <div className="flex flex-row justify-start items-start pb-4 px-6 mb-2 border-b-1 border-slate-300">
      <div className="w-1/4 h-24">
        <img
          src={item?.item?.image ?? ""}
          className="w-full h-full object-cover rounded-lg"
        />
      </div>

      <div className="w-3/4 flex flex-col ml-4">
        <p className="text-lg font-medium">
          {item?.quantity ?? 0} x {item?.item?.name ?? ""}
        </p>

        {item?.modifierGroups && item?.modifierGroups?.length
          ? item?.modifierGroups?.map((group) => (
              <div key={group.id} className="flex flex-col mt-1">
                <h5 className="font-bold text-black text-base">
                  {group?.modifier?.name ? `${group?.modifier?.name}` : ""}:{" "}
                </h5>

                <div style={{ display: "flex", flexDirection: "row" }}>
                  {group?.option?.map((opt, index) => (
                    <p
                      key={opt?.id}
                      className="font-normal text-black text-base"
                    >
                      {opt?.selectedOption?.name ?? ""}
                      {index < group.option.length - 1 ? ", " : ""}
                    </p>
                  ))}
                </div>
              </div>
            ))
          : null}

        {item?.itemNotes ? (
          <div className="flex flex-col mt-1">
            <p className="font-bold text-base">Notes:</p>

            <p className="mt-1 font-medium text-black text-base">
              {item?.itemNotes ?? ""}
            </p>
          </div>
        ) : null}

        {/* <p className="text-lg font-normal">${Number(item?.price).toFixed(2)}</p> */}
      </div>
    </div>
  );
};

export default CommonOrderProduct;
