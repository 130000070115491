import React, { useEffect, useState } from "react";
import BottomNavigationBar from "../../common/navigation/BottomNavigationBar";
import Orders from "../PageStates/Orders";
import Profile from "../PageStates/Profile";
import { changeStatusBarColor } from "../../data/device/DeviceActions";
import EventBus from "../../helpers/EventBus";
import APIController from "../../data/APIController";
import { UPDATE_PUSH_TOKEN } from "../../constants/ApiEndpoints";
import Menu from "../Menu/Menu";
import OrderStateHeader from "../../common/OrderStateHeader";
import CommonHeader from "../../common/CommonHeader";

const RestaurantPanel = () => {
  //   const restaurantService = new RestaurantAPIService();
  const [currentTab, setCurrentTab] = useState(0);

  const [currentIndex, setCurrentIndex] = useState(0);
  const orderTypes = [
    {
      id: 0,
      name: "Active",
    },
    {
      id: 1,
      name: "Past",
    },
  ];

  useEffect(() => {
    // Set the interval to run the function every 5 minutes (300000ms) after the first 2.5 seconds for the UI to load successfully.
    var intervalId;
    setTimeout(() => {
      intervalId = setInterval(callPushTokenUpdate, 300000);
    }, 2500);

    // Cleanup the interval when the component unmounts or the interval is no longer needed
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    changeStatusBarColor({ type: "dark-content", color: "#ffffff" });
    EventBus.on("updatePush", updatePushToken);

    setTimeout(() => {
      callPushTokenUpdate();
    }, 2500);

    return () => {
      EventBus.removeListener("updatePush", updatePushToken);
    };
  }, []);

  const callPushTokenUpdate = async () => {
    const message = JSON.stringify({
      type: "updatePushToken",
    });

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(message);
    }
  };

  const updatePushToken = (data) => {
    if (!data) return;
    console.log("PUSHTOKEN EVENT BUS DATA: ", data);
    APIController.post(UPDATE_PUSH_TOKEN, {
      pushToken: data.token,
    })
      .then((response) => {
        console.log("Push Token Response: ", response);
      })
      .catch((error) => {
        console.log("Error Updating Push Token: ", error);
      });
  };

  return (
    <>
      <div className="">
        {currentTab === 0 ? (
          <OrderStateHeader
            title={"Open"}
            orderTypes={orderTypes}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
          />
        ) : null}

        {currentTab > 0 && currentTab < 3 ? (
          <CommonHeader title={"Open"} />
        ) : null}

        {/* Orders Page */}
        {currentTab == 0 ? <Orders orderIndex={currentIndex} /> : null}

        {/* Table Booking Page - if enabled (Static at position 1) */}

        {/* Menu Page */}
        {currentTab == 2 ? <Menu /> : null}

        {/* Profile Page */}
        {currentTab == 3 ? <Profile /> : null}

        {/* Bottom Navigation Bar */}
        <BottomNavigationBar
          currentTab={currentTab}
          changeTab={setCurrentTab}
        />
      </div>
    </>
  );
};

export default RestaurantPanel;
