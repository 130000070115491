import moment from "moment";

export const formatOrderDate = (dateString) => {
  return moment(dateString).format("h:mm A").toString();
};

export const nowIsLater = (dateString) => {
  if (!dateString) return true;
  return moment(dateString).isBefore(moment(new Date().toISOString()));
};

export const formatDate = (dateString) => {
  return moment(dateString).format("Do MMMM YYYY").toString();
};

export const formatTime = (timeString) => {
  if (!timeString) {
    return ""; // Return empty string if input is null, undefined, or empty
  }
  const formattedTime = moment(timeString, "HH:mm:ss"); // Ensure the input format
  if (!formattedTime.isValid()) {
    return "Invalid time"; // Provide feedback if time is invalid
  }
  return formattedTime.format("h:mm A");
};

export const formatDateTime = (dateString) => {
  return moment(dateString).format("DD/MM/YY h:mm A").toString();
};

export const formatAvailableUntil = (dateString) => {
  const oneWeek = moment(new Date().toISOString()).add(1, "week");
  const dateTime = moment(dateString);
  const aheadOfWeek = dateTime.isAfter(oneWeek);

  if (aheadOfWeek) {
    return "Indefinitely";
  } else {
    return `until ${dateTime.format("Do MMM @ h:mm A")}`;
  }
};

export const getDayName = (dayNumber) => {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return days[dayNumber];
};
