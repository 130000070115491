import React from "react";
import Chart from "react-apexcharts";

const ApexAreaChart = ({ salesData }) => {
  const options = {
    chart: {
      type: "area",
      zoom: { enabled: false },
      toolbar: { show: false },
    },
    xaxis: {
      categories: salesData?.map((data) => data?.hour),
      title: { text: "Hour of Day" },
      scrollbar: {
        enabled: true, // Enable horizontal scrolling
      },
      tickAmount: 6, // Show a limited number of ticks at a time
    },
    yaxis: {
      labels: {
        formatter: (value) => `\$${value ? value.toFixed(2) : 0.0}`, // Always display with 2 decimals
      },
      title: { text: "Dollar Value ($)" },
    },
    stroke: { curve: "smooth" },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.5,
        opacityTo: 0.1,
      },
    },
    colors: ["#55B836"],
  };

  const series = [
    {
      name: "Sales Count",
      data: salesData?.map((data) =>
        data?.values ? parseFloat(Number(data?.values).toFixed(2)) : 0.0
      ),
    },
  ];

  return <Chart options={options} series={series} type="area" height={364} />;
};

export default ApexAreaChart;
