import React, { useContext } from "react";
import { RestaurantContext } from "../../data/contexts/RestaurantContext";
import SettingsItem from "../../common/SettingsItem";

import { FaRegClock, FaChartBar } from "react-icons/fa";
import { MdMonitor } from "react-icons/md";
import { LuStore } from "react-icons/lu";
import { IoReload } from "react-icons/io5";
import FilledButton from "../../common/FilledButton";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const navigate = useNavigate();
  const { restaurant } = useContext(RestaurantContext);

  return (
    <>
      <div className="flex flex-col items-start">
        {/* Restaurant Image */}
        <img
          className="rounded-lg object-cover w-full h-[25vh]"
          alt={restaurant?.name ?? ""}
          src={restaurant?.webSettings?.coverPhoto ?? ""}
        />

        {/* Information */}
        <div className="px-6 mt-5">
          <p className="font-medium text-xl">{restaurant?.name ?? ""}</p>
          <p className="font-regular text-base ">
            {restaurant?.formattedAddress ?? ""}
          </p>
        </div>
      </div>

      <div className="mt-6 px-6">
        <SettingsItem
          backgroundColor={"#006692"}
          icon={<FaChartBar size={24} color={"white"} />}
          name={"Analytics & Reports"}
          onPress={() => navigate("/settings/reporting")}
        />

        <SettingsItem
          backgroundColor={"#55B836"}
          icon={<LuStore size={24} color={"white"} />}
          name={"Integration Settings"}
          onPress={() => navigate("/settings/integrations")}
        />

        <SettingsItem
          backgroundColor={"#39B881"}
          icon={<FaRegClock size={24} color={"white"} />}
          name={"Trading Hours"}
          onPress={() => navigate("/settings/hours")}
        />

        {/* <SettingsItem
          backgroundColor={"#F0461C"}
          icon={<FaRegBell size={24} color={"white"} />}
          name={"Notifications & Updates"}
          onPress={() => {
            alert("Tapped Notifications & Update");
          }}
        /> */}

        {/* <SettingsItem
          backgroundColor={"#FFAE2D"}
          icon={<AiOutlinePrinter size={28} color={"white"} />}
          name={"Printer Settings"}
          onPress={() => {
            alert("Tapped Printer Settings");
          }}
        /> */}

        <SettingsItem
          backgroundColor={"#FF9491"}
          icon={<MdMonitor size={28} color={"white"} />}
          name={"Additional Interfaces"}
          onPress={() => navigate("/settings/interfaces")}
        />

        <SettingsItem
          backgroundColor={"#4166F5"}
          icon={<IoReload size={28} color={"white"} />}
          name={"Reload Tablet"}
          onPress={() => {
            window.location.href = "https://tablet.appetitepos.com.au/";
          }}
        />
      </div>

      <div className="mt-4 px-6">
        <FilledButton
          text={"Logout"}
          textColor={"#FF4A49"}
          backgroundColor={"#FF4A4950"}
          onPress={() => {
            localStorage.clear();
            setTimeout(() => {
              navigate("/login", { replace: true });
            }, 250);
          }}
        />
      </div>

      <div className="h-24" />
    </>
  );
};

export default Profile;
