import { useContext, useEffect, useState } from "react";
import CommonLoader from "../../common/CommonLoader";
import { FaChevronLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { AiFillPrinter } from "react-icons/ai";
import { RestaurantContext } from "../../data/contexts/RestaurantContext";
import { formatDate, formatDateTime } from "../../helpers/DateHelpers";
import HourlySalesChart from "../../common/charts/HourlySalesChart";
import CountUp from "react-countup";
import APIController from "../../data/APIController";
import { GET_REPORTS } from "../../constants/ApiEndpoints";
import { closePopup, openPopup } from "../../helpers/ModalHelpers";

const Reports = () => {
  const navigate = useNavigate();
  const { restaurant } = useContext(RestaurantContext);

  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [reportData, setReportData] = useState({});

  const getAnalytics = () => {
    APIController.get(GET_REPORTS + restaurant?.id)
      .then((response) => {
        console.log("Analytics Response: ", JSON.stringify(response.data));
        setReportData(response.data.data);
      })
      .catch((error) => {
        console.log("Error Fetching Restaurant Analytics: ", error);
      });
  };

  useEffect(() => {
    getAnalytics();
  }, []);

  return loading ? (
    <div
      className="pt-12"
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CommonLoader width={128} height={128} />
      <p className="font-medium mt-2">Updating Reports</p>
    </div>
  ) : (
    <>
      {/* Reports Actions */}
      <div className="flex flex-row justify-center items-center fixed top-0 pt-6 pb-4 px-6 bg-white w-screen">
        <FaChevronLeft
          size={20}
          onClick={() => {
            navigate("/restaurant");
          }}
        />

        <div style={{ flex: 1 }} />

        <div
          onClick={() => {
            openPopup({
              title: "Analytics Printing Available Soon",
              description:
                "Our team is working to further develop the Analytics Printing functionality. This will be enabled in a future update.",
              positiveButtonText: "Okay",
              positiveButtonTap: () => closePopup(),
            });
          }}
          className="cursor-pointer h-10 w-10 rounded-full flex justify-center items-center"
          style={{ backgroundColor: "#FA9235" }}
        >
          <AiFillPrinter size={28} color={"#ffffff"} />
        </div>
      </div>

      {/* Reports Header */}
      <div className="px-6 mt-20 pb-2">
        <h4 className="text-black font-bold text-xl">Restaurant Analytics</h4>

        <p className="text-black font-medium text-lg">
          {restaurant?.name ?? ""}
        </p>
      </div>

      {/* Date & Filter */}
      <div className="px-6 mb-4 flex flex-row justify-center items-center">
        <p className="text-black font-medium text-base">
          {formatDate(selectedDate.toISOString())}
        </p>

        <div style={{ flex: 1 }} />

        <div className="w-1/3">
          {/* <FilledButton
            text={"Filter"}
            paddingVertical={6}
            backgroundColor={"#038000"}
            textColor={"#ffffff"}
            onPress={() => {
              console.log("Tapped Filter");
            }}
          /> */}
        </div>
      </div>

      {/* Revenue Counters */}
      <div className="px-6 grid grid-cols-2 gap-2 py-3 border-slate-300 border-t-1 border-b-1">
        {/* Total Revenue */}
        <div className="border-r-1 border-slate-300">
          <h4 className="text-black text-lg font-medium mb-1">Total Revenue</h4>
          <CountUp
            start={0.0}
            end={reportData?.revenue ?? 0.0}
            decimals={2}
            prefix="$"
          >
            {({ countUpRef, start }) => {
              setTimeout(() => {
                start();
              }, 150);
              return (
                <p ref={countUpRef} className="text-black font-bold text-2xl" />
              );
            }}
          </CountUp>
        </div>

        {/* Total Discounts */}
        <div>
          <h4 className="text-black text-lg font-medium mb-1">
            Total Discounts
          </h4>
          <CountUp
            start={0.0}
            end={reportData?.discount ?? 0.0}
            decimals={2}
            prefix="$"
          >
            {({ countUpRef, start }) => {
              setTimeout(() => {
                start();
              }, 150);
              return (
                <p ref={countUpRef} className="text-black font-bold text-2xl" />
              );
            }}
          </CountUp>
        </div>
      </div>

      {/* Delivery Counters */}
      <div className="px-6 grid grid-cols-2 gap-2 py-3 border-slate-300 border-b-1">
        {/* Total Revenue */}
        <div className="border-r-1 border-slate-300">
          <h4 className="text-black text-lg font-medium mb-1">
            Total Deliveries
          </h4>
          <CountUp start={0.0} end={reportData?.deliveries}>
            {({ countUpRef, start }) => {
              setTimeout(() => {
                start();
              }, 150);
              return (
                <p ref={countUpRef} className="text-black font-bold text-2xl" />
              );
            }}
          </CountUp>
        </div>

        {/* Total Discounts */}
        <div>
          <h4 className="text-black text-lg font-medium mb-1">Delivery Fees</h4>
          <CountUp
            start={0.0}
            end={reportData?.deliveryFees ?? 0.0}
            decimals={2}
            prefix="$"
          >
            {({ countUpRef, start }) => {
              setTimeout(() => {
                start();
              }, 150);
              return (
                <p ref={countUpRef} className="text-black font-bold text-2xl" />
              );
            }}
          </CountUp>
        </div>
      </div>

      {/* Hourly Stats */}
      <div className="px-6 mt-4">
        <h4 className="text-black font-medium text-base">Hourly Sales</h4>

        <HourlySalesChart salesData={reportData?.hourlySales ?? []} />
      </div>

      {/* Report Generated Time */}
      <div className="px-6 mt-8 flex flex-col justify-center items-center">
        <p className="text-black font-normal text-base">
          Report generated for{" "}
          <span className="font-medium">{restaurant?.name ?? ""}</span>
        </p>

        <p className="text-black font-normal text-base">
          as at{" "}
          <span className="font-medium">
            {formatDateTime(new Date().toISOString())}
          </span>
        </p>
      </div>
    </>
  );
};

export default Reports;
