import React from "react";
import FilledButton from "../FilledButton";

import { ring2 } from "ldrs";

ring2.register();

const PreparingButtonGroup = ({ onReady, onActions, isReadying }) => {
  return (
    <div className="grid grid-cols-2 gap-2">
      <div className="w-full flex justify-center items-center">
        {!isReadying ? (
          <FilledButton
            text={"Order Ready"}
            paddingVertical={10}
            backgroundColor={"#228B22"}
            onPress={onReady}
          />
        ) : (
          <>
            <l-ring-2
              size="40"
              stroke="5"
              stroke-length="0.25"
              bg-opacity="0.1"
              speed="0.8"
              color="#55B836"
            ></l-ring-2>
          </>
        )}
      </div>

      <div className="w-full">
        <FilledButton
          text={"Order Actions"}
          paddingVertical={10}
          backgroundColor={"#4166F5"}
          onPress={onActions}
        />
      </div>
    </div>
  );
};

export default PreparingButtonGroup;
