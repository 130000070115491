import EventBus from "./EventBus";

export const openPopup = ({
  title,
  description,
  positiveButtonText,
  positiveButtonTap,
  negativeButtonText,
  negativeButtonTap,
  actionButtonText,
  actionButtonTap,
}) => {
  EventBus.emit("openModal", {
    title,
    description,
    positiveButtonText,
    positiveButtonTap,
    negativeButtonText,
    negativeButtonTap,
    actionButtonText,
    actionButtonTap,
  });
};

export const closePopup = () => {
  EventBus.emit("closeModal");
};
