import React from "react";
import { useNavigate } from "react-router-dom";
import {
  getFormattedOrderStatus,
  getOrderColor,
  getOrderTextColor,
} from "../helpers/OrderHelpers";
import { toSentenceCase } from "../helpers/StringHelpers";
import { formatOrderDate, nowIsLater } from "../helpers/DateHelpers";

const CommonOrderItem = ({ order }) => {
  const navigate = useNavigate();
  return (
    <>
      <div
        onClick={() => navigate(`/order/${order?.id ?? ""}`)}
        style={{
          backgroundColor: getOrderColor(order?.status ?? "awaiting"),
          color: getOrderTextColor(order?.status ?? "awaiting"),
        }}
        className="mt-2 px-4 py-3 rounded-xl"
      >
        <p className="text-white font-medium text-lg">
          Order #{order?.bookingId?.toUpperCase() ?? ""}
        </p>

        <p className="text-white font-medium text-base mb-2">
          {getFormattedOrderStatus(order?.status ?? "awaiting")}
        </p>

        <p className="text-white font-medium text-base">
          {order?.customer?.firstName ?? ""} {order?.customer?.lastName ?? ""}
        </p>

        <p className="text-white font-medium text-base">
          {toSentenceCase(order?.orderType ?? "")}
        </p>
      </div>
    </>
  );
};
export default CommonOrderItem;
