import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CommonLoader from "../../common/CommonLoader";
import APIController from "../../data/APIController";
import { GET_RESTAURANT_MENU_ITEM } from "../../constants/ApiEndpoints";
import { FaChevronLeft } from "react-icons/fa";
import CommonModifierGroup from "../../common/CommonModifierItem";

const EditMenuItem = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState({});

  const getProduct = () => {
    console.log("PRODUCT ID IS: ", id);
    setLoading(true);
    APIController.get(GET_RESTAURANT_MENU_ITEM + id)
      .then((response) => {
        console.log("Product Details: ", response.data);
        setProduct(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!id) return;
    getProduct();
  }, [id]);

  return (
    <>
      {loading ? (
        <div
          className="pt-12"
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CommonLoader width={128} height={128} />
          <p className="font-medium mt-2">Updating Menu Item</p>
        </div>
      ) : (
        <>
          <div className="flex flex-row fixed top-0 pt-6 pb-4 px-6 bg-white w-screen">
            <FaChevronLeft
              size={20}
              onClick={() => {
                navigate("/restaurant");
              }}
            />

            <div style={{ flex: 1 }} />
            <p className="text-base font-medium">{product?.name ?? ""}</p>
            <div style={{ flex: 1 }} />

            <FaChevronLeft size={20} color={"#ffffff"} />
          </div>

          <div className="px-6 mt-16 pb-4">
            <img
              className="w-full h-40 object-cover rounded-xl"
              src={product?.image ?? ""}
            />

            <h4 className="font-medium text-base mt-3">
              {product?.name ?? ""}
            </h4>

            <h4 className="font-normal text-base mt-1">
              In-Store: ${product?.instorePrice ?? ""} | Pickup: $
              {product?.pickupPrice ?? ""} | Delivery: $
              {product?.deliveryPrice ?? ""}
            </h4>

            {product?.modifiers && product?.modifiers?.length > 0
              ? product?.modifiers?.map((modifier) => (
                  <CommonModifierGroup
                    key={modifier?.id}
                    item={modifier}
                    onChange={(product) => {
                      if (product?.available) {
                        console.log("Tapped Deactivate Modifier");
                      } else {
                        console.log("Tapped Reactivate Modifier");
                      }
                    }}
                  />
                ))
              : null}
          </div>
        </>
      )}
    </>
  );
};

export default EditMenuItem;
